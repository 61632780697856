<template>
  <MnemonicImage
    class="GameChoiceItem --global-clickable"
    :class="[`state-${state}`]"
    :image="image"
    :focus="true"
    @click.native="handleClick" />
</template>

<script>
import { TimelineLite, Power2 } from 'gsap';
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        image: {
            type: String,
            required: true
        },
        matched: {
            type: Boolean
        },
        wrongCount: {
            type: Number
        }
    },
    data() {
        return {
            state: 'ready'
        };
    },
    watch: {
        wrongCount(value) {
            this.onWrong();
        },
        matched(value) {
            this.onCorrect();
        }
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        handleClick() {
            if (this.state === 'ready') {
                this.$emit('click');
            }
        },
        onCorrect() {
            this.state = 'correct';
            this.tl = new TimelineLite({
                onComplete: () => {}
            });
            this.tl.to(this.$el, 0.1, {
                scale: 1.1,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.3, {
                scale: 0.7,
                opacity: 0,
                ease: Power2.easeInOut
            });
        },
        onWrong() {
            this.state = 'wrong';
            this.tl = new TimelineLite({
                onComplete: () => {
                    this.state = 'ready';
                }
            });
            this.tl.to(this.$el, 0.1, {
                x: -7,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                repeat: 4,
                x: 7,
                yoyo: true,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                x: 0
            });
        }
    }
};
</script>

<style scoped lang="scss">
.GameChoiceItem {
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;

    animation: appear 0.3s;
    @keyframes appear {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
}
</style>
