export default function stripMarkup(summary, mnemonics) {
    let adjustments = [];
    summary = summary.replace(/(<span class="topic">|<\/span>)/g, (m, g1, pos) => {
        adjustments.push({
            start: pos,
            length: g1.length
        });
        return '';
    });
    mnemonics = mnemonics.map(mnemonic => {
        mnemonic = Object.assign({}, mnemonic);
        mnemonic.factRefs = mnemonic.factRefs.map(ref => {
            const origStart = ref.offset;
            ref = Object.assign({}, ref);
            adjustments.forEach(adjustment => {
                if (origStart >= adjustment.start + adjustment.length) {
                    ref.offset -= adjustment.length;
                }
            });
            return ref;
        });
        return mnemonic;
    });
    return {
        summary,
        mnemonics
    };
}
