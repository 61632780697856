import shuffle from 'lodash-es/shuffle';
import memomapsDataHelper from '@/store/modules/module-course/memomaps-data-helper';
import { DataProvider } from './DataProvider';
import prepareData from './prepare-data';
import store from '@/store';
import flatten from 'lodash-es/flatten';

function rotatePool(pool, exclude) {
    if (pool[0] === exclude) {
        const a = pool.shift();
        const b = pool.shift();
        pool.unshift(a);
        pool.push(b);
        return b;
    } else {
        const a = pool.shift();
        pool.push(a);
        return a;
    }
}

export default async function historyStepQuiz2(quiz, { stepId }) {
    const history100Kb = await memomapsDataHelper.loadHistory100Kb();
    const step = store.state.moduleCourse.steps[stepId];
    const journey = store.state.moduleCourse.stepGroups[step.stepGroupId];

    const memoIdsPool = shuffle(
        flatten(
            journey.stepIds
                .map(stepId => store.state.moduleCourse.steps[stepId])
                .map(step => {
                    if (step.memorize2) {
                        return step.memorize2.memoIds;
                    } else {
                        return [];
                    }
                })
        )
    );

    const questions = step.memorize2.memoIds.map(memoId => {
        const x = history100Kb[memoId];
        const year = Math.abs(x.year);
        const beforeChrist = x.year < 0;

        return {
            question: `What happened in year ${year}${beforeChrist ? ' BC?' : '?'}`,
            choices: [
                { answer: x.fact, isCorrect: true },
                { answer: history100Kb[rotatePool(memoIdsPool, memoId)].fact, isCorrect: false },
                { answer: history100Kb[rotatePool(memoIdsPool, memoId)].fact, isCorrect: false },
                { answer: history100Kb[rotatePool(memoIdsPool, memoId)].fact, isCorrect: false }
            ]
        };
    });

    return new DataProvider(prepareData(shuffle(questions)));
}
