<template>
  <div class="SegmentedProgressBar">
    <div
      v-for="(segment, i) of segments"
      :key="i"
      class="Segment"
      :class="{ done: segment.done }"
      :style="{ backgroundColor: styles[segment.style] }" />
  </div>
</template>

<script>
export default {
    props: {
        segments: {
            type: Array,
            default: function () {
                return [];
            }
        },
        styles: {
            type: Object,
            default: function () {
                return {};
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$correctColor: #93c60b;
$height: 3px;

.SegmentedProgressBar {
    background-color: white;
    display: flex;
    height: $height;
}
.Segment {
    height: $height;
    flex: 1;
    transform: scaleX(0);
    transform-origin: left center;
    &.done {
        background-color: $correctColor;
        transform: scaleX(1);
        transition: transform 0.5s;
    }
}
</style>
