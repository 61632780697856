import store from '@/store';
import { DataProvider } from './DataProvider';

export default function memoGameAForCourse(quiz, { courseId, stepId, lstore = store, activityName = 'memorize' }) {
    const course = lstore.state.moduleCourse.courses[courseId];
    const stepIds = []
    course.stepGroupIds.forEach(stepGroupId => {
      const stepGroup = lstore.state.moduleCourse.stepGroups[stepGroupId];
      stepGroup.stepIds.forEach(stepId => {
        stepIds.push(stepId)
      })
    })
    const quizItems = [];
    // const stepIds = stepId ? [stepId] : stepGroup.stepIds;

    // function preparePropsForMemogameA (origSummary, origMnemonics) {
    //   return {
    //     pairs: origMnemonics.map(mnemonic => {
    //       let ref = mnemonic.factRefs[0]
    //       return {
    //         text: origSummary.substr(ref.offset, ref.length),
    //         image: mnemonic.imageUrl || mnemonic.numbershape
    //       }
    //     })
    //   }
    // }

    stepIds
        .map(stepId => lstore.state.moduleCourse.steps[stepId])
        .filter(step => step[activityName] !== null)
        .forEach(step => {
            const memoIds = step[activityName].memoIds;
            memoIds
                .map(memoId => lstore.state.moduleCourse.memos[memoId])
                .forEach(memo => {
                    // console.log('step', step)
                    const items = [];

                    // const memorize = step[activityName]
                    // const memoId = memorize.memoIds[0]
                    // const memo = lstore.state.moduleCourse.memos[memoId]
                    const text = memo.fact.text;
                    const mnemonics = memo.fantasies[0].mnemonics;
                    mnemonics.forEach(mnemonic => {
                        const ref = mnemonic.factRefs[0];
                        const textFrag = text.substr(ref.offset, ref.length);

                        items.push({
                            target: textFrag,
                            // source: { image: mnemonic.imageUrl || mnemonic.numbershape }
                            image: mnemonic.imageUrl || mnemonic.numbershape
                        });
                    });

                    quizItems.push({
                        type: 'fallingMatch',
                        // question: 'Match the highlighted words from the step summaries with the right memory images.',
                        question: 'Match nøkkelordene fra sammendraget med huskebildene',
                        items
                    });
                    // let pairs = preparePropsForMemogameA(text, mnemonics).pairs
                    // pairs = [pairs[0], ...shuffle(pairs.slice(1))]
                    // return {
                    //   pairs, // : pairs.slice(0, 6),
                    //   shuffle: false,
                    //   // discardOnFail: true,
                    //   // maxItemsOnShelf: 4,
                    //   remainingLivesInitialValue: this.remainingLives
                    // }
                });
        });

    return new DataProvider(quizItems);
}
