import { DataProvider } from './DataProvider';

export default function multiMultipleChoice(quiz) {
    const { question, choices } = JSON.parse(quiz.generatorOptions);
    const item = {
        type: 'multiMultipleChoice',
        question,
        choices
    };

    return new DataProvider([item]);
}
