import shuffle from 'lodash-es/shuffle';
import memomapsDataHelper from '@/store/modules/module-course/memomaps-data-helper';
import { DataProvider } from './DataProvider';
import prepareData from './prepare-data';

export default async function periodicElementStepQuiz2(quiz, { stepId }) {
    const { numbers } = JSON.parse(quiz.generatorOptions);

    const chemistryKb = await memomapsDataHelper.loadChemistryKb();

    const questions = [];
    numbers.forEach(number => {
        const question = {
            question: `Hva er det ${number}. elementet i periodesystemet?`,
            choices: numbers.map(n => {
                const element = chemistryKb.elements.find(e => e.number === n);
                return {
                    answer: `${element.name} (${element.abbr})`,
                    isCorrect: n === number
                };
            })
        };
        questions.push(question);
    });

    // numbers.forEach(number => {
    //   const elem = chemistryKb.elements.find(e => e.number === number)
    //   if (elem.functions) {
    //     const question = {
    //       question: `Hvor finnes ${elem.name}?`,
    //       choices: numbers.map(n => {
    //         return {
    //           answer: (chemistryKb.elements.find(e => e.number === n).functions || [])[0],
    //           isCorrect: n === number
    //         }
    //       }).filter(c => c.answer)
    //     }
    //     questions.push(question)
    //   }
    // })

    return new DataProvider(prepareData(shuffle(questions)));
}
