<template>
  <div class="FallingItemsCanvas">
    <FallingItem
      v-for="(item, i) of items"
      :key="i"
      class="FallingItem"
      :stop="item.stop"
      @timeout="$emit('item-timeout', i)">
      <slot
        name="item"
        :index="i"
        xv-bind="item" />
    </FallingItem>
  </div>
</template>

<script>
import FallingItem from './FallingItem';

export default {
    components: { FallingItem },
    data() {
        return {
            items: []
        };
    },
    created() {
        // this.items = [{ id: 'x1' }, { id: 'x2' }]
    },
    methods: {
        release(itemId) {
            this.items.push({ id: itemId });
        },
        stop() {
            const item = this.items[this.items.length - 1];
            if (item) {
                item.stop = true;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.FallingItemsCanvas {
    position: relative;
}
// .FallingItem {
//   border: 1px solid yellow;
// }
</style>
