import shuffle from 'lodash-es/shuffle';
import memomapsDataHelper from '@/store/modules/module-course/memomaps-data-helper';
import { DataProvider } from './DataProvider';
// import prepareData from './prepare-data'
import store from '@/store';
import flatten from 'lodash-es/flatten';

// function rotatePool (pool, exclude) {
//   if (pool[0] === exclude) {
//     const a = pool.shift()
//     const b = pool.shift()
//     pool.unshift(a)
//     pool.push(b)
//     return b
//   } else {
//     const a = pool.shift()
//     pool.push(a)
//     return a
//   }
// }

// function formatYear (year) {
//   const beforeChrist = year < 0
//   year = Math.abs(year)
//   return `${year}${beforeChrist ? ' BC' : ''}`
// }

export default async function historyJourneyQuiz(quiz, { stepGroupId, quizState }) {
    const history100Kb = await memomapsDataHelper.loadHistory100Kb();
    const journey = store.state.moduleCourse.stepGroups[stepGroupId];

    const completionMap = {};
    if (quizState) {
        quizState.allCompletedItems.forEach(item => {
            completionMap[item.id] = true;
        });
    }

    const memoIds = flatten(
        journey.stepIds
            .map(stepId => store.state.moduleCourse.steps[stepId])
            .map(step => {
                if (step.memorize2) {
                    return step.memorize2.memoIds;
                } else {
                    return [];
                }
            })
    );

    const items = memoIds
        .map(memoId => {
            const x = history100Kb[memoId];
            return {
                type: 'whenQuestionWithNumberInput',
                question: 'When did this happen?',
                subject: x.fact,
                fasit: x.year,
                itemId: memoId, // something unique and stable
                itemSource: {
                    memoId,
                    question: 'When did this happen?'
                    // question: `What happened in ${formatYear(x.year)}?`
                }
            };
        })
        .filter(item => !completionMap[item.id]);

    return new DataProvider(shuffle(items));

    // For multiple choice questions:

    // const memoIdsPool = shuffle(memoIds)
    // const questions = memoIds.map(memoId => {
    //   const x = history100Kb[memoId]

    //   return {
    //     question: `When did this happen? -- "${x.fact}"`,
    //     choices: [
    //       { answer: formatYear(x.year), isCorrect: true },
    //       { answer: formatYear(history100Kb[rotatePool(memoIdsPool, memoId)].year), isCorrect: false },
    //       { answer: formatYear(history100Kb[rotatePool(memoIdsPool, memoId)].year), isCorrect: false },
    //       { answer: formatYear(history100Kb[rotatePool(memoIdsPool, memoId)].year), isCorrect: false }
    //     ]
    //   }
    // })
    // return new DataProvider(prepareData(shuffle(questions)))
}
