import store from '@/store';
import shuffle from 'lodash-es/shuffle';
import { DataProvider } from './DataProvider';
import prepareData from './prepare-data';

export default async function imageMatchQuiz(quiz, { stepId }) {
    const memos = store.state.moduleCourse.steps[stepId].memorize2.memoIds.map(memoId => {
        return store.state.moduleCourse.memos[memoId];
    });
    const questions = [];
    const allChoices = [];
    memos.forEach(memo => {
        if (memo.fact.image) {
            allChoices.push({
                memoId: memo.id,
                answer: memo.fact.image
            });
        }
    });

    memos.forEach(memo => {
        const question = {
            question: `Hvilket bilde er "${memo.fact.text}"?`,
            choices: [
                ...shuffle(allChoices.filter(c => c.memoId !== memo.id)).slice(0, 3),
                { answer: memo.fact.image, isCorrect: true }
            ]
        };
        questions.push(question);
    });
    return new DataProvider(prepareData(questions, { choicesAreImages: true }));
}
