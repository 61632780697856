import shuffle from 'lodash-es/shuffle';
import memomapsDataHelper from '@/store/modules/module-course/memomaps-data-helper';
import { DataProvider } from './DataProvider';
import store from '@/store';
import flatten from 'lodash-es/flatten';

export default async function chemistryStepGroupQuiz(quiz, { stepGroupId, quizState }) {
    const chemistryKb = await memomapsDataHelper.loadChemistryKb();
    const journey = store.state.moduleCourse.stepGroups[stepGroupId];

    const { elementNumbers } = JSON.parse(quiz.generatorOptions);
    const items = [];

    const memoMapping = {};
    const memoIds = flatten(
        journey.stepIds
            .map(stepId => store.state.moduleCourse.steps[stepId])
            .map(step => {
                if (step.memorize2) {
                    return step.memorize2.memoIds;
                } else {
                    return [];
                }
            })
    );
    memoIds.forEach(memoId => {
        const memo = store.state.moduleCourse.memos[memoId];
        memoMapping[memo.fact.options.number] = memoId;
    });

    const completionMap = {};
    if (quizState) {
        quizState.allCompletedItems.forEach(item => {
            completionMap[item.id] = true;
        });
    }

    let allFunctions = new Set(
        elementNumbers.map(elementNumber => {
            const elementData = chemistryKb.elements[elementNumber - 1];
            return (elementData.functions || [])[0];
        })
    );
    allFunctions.delete(undefined);
    allFunctions = Array.from(allFunctions);

    function generateMultipleChoiceA(elementData) {
        const choices = shuffle(allFunctions).slice(0, 4);
        const elementFunction = (elementData.functions || [])[0];
        const fasitIndex = choices.indexOf(elementFunction);
        if (fasitIndex !== -1) {
            choices.splice(fasitIndex, 1);
            choices.unshift(elementFunction);
        } else {
            choices.pop();
            choices.unshift(elementFunction);
        }
        return {
            type: 'multipleChoice',
            question: `Hvor finnes ${elementData.name}?`,
            options: choices
        };
    }

    function generateMultipleChoiceB(elementData) {
        let allGroupNumbers = new Set(
            elementNumbers.map(elementNumber => {
                const elementData = chemistryKb.elements[elementNumber - 1];
                return elementData.group;
            })
        );
        allGroupNumbers = Array.from(allGroupNumbers).filter(groupNumber => groupNumber !== elementData.group);
        allGroupNumbers.unshift(elementData.group);
        return {
            type: 'multipleChoice',
            question: `Hvilken gruppe hører ${elementData.name} til?`,
            options: allGroupNumbers.map(groupNumber => {
                return `Gruppe ${groupNumber}`;
            })
        };
    }

    elementNumbers.forEach((elementNumber) => {
        if (completionMap[elementNumber]) {
            return;
        }
        const elementData = chemistryKb.elements[elementNumber - 1];
        let subs = [];
        if (elementData.functions) {
          subs = [
            generateMultipleChoiceA(elementData),
            generateMultipleChoiceB(elementData),
          ];
        } else {
          subs = [generateMultipleChoiceB(elementData)];
        }
        items.push({
            target: `${elementNumber}`,
            source: elementData.abbr,
            subs,
            itemId: elementNumber,
            itemSource: {
                memoId: memoMapping[elementNumber]
            }
        });
    });

    return new DataProvider([
        {
            type: 'multipleMatch',
            question: 'Dra og slipp svarene',
            items
        }
    ]);
}
