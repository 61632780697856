export default function prepareData(questions, options = null) {
    const items = [];
    const choicesAreImages = options && options.choicesAreImages;
    questions.forEach((question, i) => {
        const correctIndex = question.choices.findIndex(x => x.isCorrect);
        if (correctIndex > 0) {
            const correctChoice = question.choices[correctIndex];
            question.choices.splice(correctIndex, 1);
            question.choices.unshift(correctChoice);
        }
        items.push({
            type: 'multipleChoice',
            question: question.question,
            options: question.choices.map(x => {
                return {
                    image: choicesAreImages ? x.answer : null,
                    text: !choicesAreImages ? x.answer : null
                };
            })
        });
    });
    return items;
}
