import shuffle from 'lodash-es/shuffle';
import memomapsDataHelper from '@/store/modules/module-course/memomaps-data-helper';
import { DataProvider } from './DataProvider';

export default async function periodicElementStepQuiz1(quiz, { stepId }) {
    const { groupNumber, numbers } = JSON.parse(quiz.generatorOptions);

    const chemistryKb = await memomapsDataHelper.loadChemistryKb();

    const questions = [];
    let choices = numbers.map(n => {
        const element = chemistryKb.elements.find(e => e.number === n);
        return {
            text: `${element.name} (${element.abbr})`,
            isCorrect: true
        };
    });

    const allElements = shuffle(chemistryKb.elements);
    for (let i = 0; i < allElements.length; i++) {
        const element = allElements[i];
        if (element.group !== groupNumber) {
            choices.push({
                text: `${element.name} (${element.abbr})`
            });
        }
        if (choices.length === numbers.length * 2) {
            break;
        }
    }

    const question = {
        type: 'multiMultipleChoice',
        // question: `Select the elements belonging to group ${groupNumber}`,
        question: `Velg elementene som hører med til gruppe ${groupNumber}`,
        choices
    };
    questions.push(question);

    return new DataProvider(questions);
}
