<template>
  <div class="FallingItem">
    <slot />
  </div>
</template>

<script>
import { TimelineMax, Power0 } from 'gsap';

export default {
    props: {
        stop: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        stop() {
            if (this.fallingTimeline) {
                this.fallingTimeline.pause();
            }
        }
    },
    mounted() {
        this.fallingDuration = 7000;

        let elem = this.$el;
        const height = elem.clientHeight;
        const width = elem.clientWidth;
        const containerHeight = elem.parentElement.clientHeight;
        const containerWidth = elem.parentElement.clientWidth;

        if (width < containerWidth) {
            const remainingSpace = containerWidth - width;
            const randomLeftPos = Math.round(Math.random() * remainingSpace);
            elem.style.left = `${randomLeftPos}px`;
        }
        this.fallingTimeline = new TimelineMax();
        this.fallingTimeline.to(
            elem,
            this.fallingDuration / 1000,
            {
                ease: Power0.easeNone,
                y: containerHeight - height
            },
            0
        );
        this.fallingTimeline.call(this.onTimeout, [], this, '-=0.3');
    },
    beforeDestroy() {
        if (this.fallingTimeline) {
            this.fallingTimeline.kill();
        }
    },
    methods: {
        onTimeout() {
            this.$emit('timeout');
        }
    }
};
</script>

<style scoped lang="scss">
.FallingItem {
    position: absolute;
    top: 0;
    left: 0;

    & > * {
        animation: appear 0.3s;

        @keyframes appear {
            0% {
                transform: scale(0, 1);
                xtransform-origin: center top;
                opacity: 0;
            }
            100% {
                transform: scale(1, 1);
                opacity: 1;
            }
        }
    }
}
</style>
