import periodicElementStepQuiz1 from './periodicElementStepQuiz1';
import periodicElementStepQuiz2 from './periodicElementStepQuiz2';
import chemistryStepGroupQuiz from './chemistryStepGroupQuiz';

import historyStepQuiz2 from './historyStepQuiz2';
import historyJourneyQuiz from './historyJourneyQuiz';

import memoGameA from './memoGameA';
import memoGameAForCourse from './memoGameAForCourse';
import memoGameB from './memoGameB';
import memoGameAB from './memoGameAB';

import standardStepQuiz from './standardStepQuiz';
import standardStepGroupQuiz from './standardStepGroupQuiz';
import standardCourseQuiz from './standardCourseQuiz';
import imageMatchQuiz from './imageMatchQuiz';
import multiMultipleChoice from './multiMultipleChoice';

const generators = {
    periodicElementStepQuiz1,
    periodicElementStepQuiz2,
    chemistryStepGroupQuiz,

    historyStepQuiz2,
    historyJourneyQuiz,

    memoGameA,
    memoGameAForCourse,
    memoGameB,
    memoGameAB,

    standardStepQuiz,
    standardStepGroupQuiz,
    standardCourseQuiz,
    imageMatchQuiz,

    multiMultipleChoice
};

export function createQuestionProvider(quiz, context) {
    return generators[quiz.generator || 'standardStepQuiz'](quiz, context);
}

export default {
    createQuestionProvider
};
