import store from '@/store';
import { DataProvider } from './DataProvider';
import stripMarkup from './strip-markup';

export default async function memoGameB(
    quiz,
    { stepGroupId, stepId, lstore = store, activityName = 'memorize', options }
) {
    const stepGroup = lstore.state.moduleCourse.stepGroups[stepGroupId];
    const quizItems = [];
    const stepIds = stepId ? [stepId] : stepGroup.stepIds;
    const maxItems = 8;
    const preselect = (options && options.gameB && options.gameB.preselect) || 0;

    stepIds
        .map(stepId => lstore.state.moduleCourse.steps[stepId])
        .forEach(step => {
            const memoIds = step[activityName].memoIds;
            memoIds
                .map(memoId => lstore.state.moduleCourse.memos[memoId])
                .forEach((memo, memoIndex) => {
                    const text = memo.fact.text;
                    const origMnemonics = memo.fantasies[0].mnemonics;
                    const { summary, mnemonics } = stripMarkup(text, origMnemonics);

                    const items = mnemonics.map(mnemonic => {
                        let ref = mnemonic.factRefs[0];
                        return {
                            image: mnemonic.imageUrl || mnemonic.numbershape,
                            range: {
                                start: ref.offset,
                                length: ref.length
                            }
                        };
                    });
                    if (memoIds.length > 1 && items.length < maxItems) {
                        for (let i = memoIndex + 1; i < memoIndex + items.length; i++) {
                            const extraMemo = lstore.state.moduleCourse.memos[memoIds[i % memoIds.length]];
                            const extraMnemonics = extraMemo.fantasies[0].mnemonics.slice(1);

                            extraMnemonics.forEach(mnemonic => {
                                const image = mnemonic.imageUrl || mnemonic.numbershape;
                                if (items.length < maxItems && !items.find(x => x.image === image)) {
                                    items.push({
                                        image,
                                        range: null
                                    });
                                }
                            });
                        }
                    }
                    quizItems.push({
                        type: 'pickInSequence',
                        renderer: 'RecreateMemo',
                        // question: 'Pick in right order to recreate summary',
                        question: 'Visualiser og dra bildene i riktig rekkefølge for å gjenkalle sammendraget.',
                        text: summary,
                        items,
                        preselect
                    });
                });
        });

    return new DataProvider(quizItems);
}
