import store from '@/store';
import { DataProvider } from './DataProvider';
import shuffle from 'lodash-es/shuffle';

function prepMultipleChoice(question) {
    return {
        type: 'multipleChoice',
        question: question.question,
        options: question.choices.map(x => {
            return {
                text: x.answer
            };
        })
    };
}

export default async function standardCourseQuiz(quiz, { courseId, quizState }) {
    let items = [];

    const course = store.getters['moduleCourse/getCourseById'](courseId);

    course.stepGroupIds.forEach(stepGroupId => {
      const stepGroup = store.state.moduleCourse.stepGroups[stepGroupId];
      stepGroup.stepIds
          .map(stepId => store.state.moduleCourse.steps[stepId])
          .forEach(step => {
              if (step.quiz && (!step.quiz.generator || step.quiz.generator === 'standardStepQuiz')) {
                  const memoId = (step.memorize && step.memorize.memoIds[0]) || null;
                  const shuffQuestions = shuffle(step.quiz.questions) // temp solution for limiting quiz questions
                  const questions = shuffQuestions.slice(0, 1) // temp solution for limiting quiz questions
                  questions.forEach((question, i) => {
                      const item = prepMultipleChoice(question);
                      item.itemId = `${step.id}:${i + 1}`;
                      if (memoId) {
                          item.itemSource = {
                              question: question.question,
                              memoId: memoId
                          };
                      }
                      items.push(item);
                  });
              }

              if (step.quiz2 && step.quiz2.generator === 'multiMultipleChoice') {
                  // NOTE: need more generic code eventually. For now it will support quiz2 in ls-02
                  const { question, choices } = JSON.parse(step.quiz2.generatorOptions);
                  items.push({
                      type: 'multiMultipleChoice',
                      question,
                      choices,

                      itemId: `${step.id}:quiz2`
                      // itemSource: {
                      //   question: question,
                      //   memoId: question.memoId
                      // }
                  });
              }
          });
    })
    
    if (quizState) {
        const completionMap = {};
        quizState.allCompletedItems.forEach(item => {
            completionMap[item.id] = true;
        });

        items = items.filter(item => !completionMap[item.questionId]);
    }

    return new DataProvider(shuffle(items));
}
