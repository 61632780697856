<template>
  <div
    class="FallingItemContent"
    :class="[`state-${state}`]">
    {{ text }}
  </div>
</template>

<script>
import { TimelineLite, Power2 } from 'gsap';

export default {
    props: {
        text: {
            type: String,
            required: true
        },
        state: {
            type: String
        }
    },
    watch: {
        state(value) {
            if (value === 'correct') {
                this.onCorrect();
            } else if (value === 'wrong') {
                this.onWrong();
            } else if (value === 'timeout') {
                this.onTimeout();
            }
        }
    },
    methods: {
        onCorrect() {
            this.tl = new TimelineLite();
            this.tl.to(this.$el, 0.2, {
                scale: 1.2,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.3, {
                scale: 0.7,
                opacity: 0,
                ease: Power2.easeInOut
            });
        },
        onWrong() {
            this.tl = new TimelineLite();
            // this.tl.to(this.$el, 0.2, {
            //   scale: 1.2,
            //   ease: Power2.easeInOut
            // })
            this.tl.to(this.$el, 0.5, {
                opacity: 0,
                ease: Power2.easeInOut
            });
            // this.tl.to(this.$el, 0.1, {
            //   x: -7,
            //   ease: Power2.easeInOut
            // })
            // this.tl.to(this.$el, 0.1, {
            //   repeat: 4,
            //   x: 7,
            //   yoyo: true,
            //   ease: Power2.easeInOut
            // })
            // this.tl.to(this.$el, 0.1, {
            //   x: 0
            // })
            // this.tl.to(this.$el, 0.5, {
            //   opacity: 0,
            //   ease: Power2.easeInOut
            // }, '-=0.5')
        },
        onTimeout() {
            this.tl = new TimelineLite();
            this.tl.to(this.$el, 0.5, {
                opacity: 0,
                ease: Power2.easeInOut
            });
            // this.tl.to(this.$el, 0.5, {
            //   scale: 1.3,
            //   opacity: 0,
            //   ease: Power2.easeInOut
            // })
        }
    }
};
</script>

<style scoped lang="scss">
.FallingItemContent {
    background-color: white;
    padding: 0.5em 1em;
    border-radius: 2em;
    color: black;
    text-align: center;

    &.state-correct {
        background-color: green;
        color: white;
    }
    &.state-wrong {
        background-color: red;
        color: white;
    }
    &.state-timeout {
        background-color: red;
        color: white;
    }
}
</style>
